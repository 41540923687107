<template>
  <ion-page>
    <ion-content class="ion-padding-start ion-padding-end top-safe-space">
      <UserLoggedCard v-if="isLogged && canShowAlreadyLoggedCard" />
      <div
        v-else
        class="main-content tablet-centered"
      >
        <div class="pt-6">
          <div class="is-flex ion-justify-content-center">
            <AppImg
              is-transparent
              img-src="./assets/medicast-logo.png"
              style="width: 100px; height: 100px"
            />
          </div>

          <h2 class="title fw-900 mb-3 ion-text-center">Medicast</h2>
          <h3 class="subtitle fw-700 ion-text-center">MedischeScholing</h3>
          <h5 class="fw-400 mb-3 ion-text-center">{{ $t('login.loginWith') }} <span class="has-text-primary">MedischeScholing</span> account</h5>
        </div>


        <div
          class="
            login-form
            is-flex is-flex-direction-column
            ion-justify-content-between"
        >
          <div>
            <transition
              name="fade"
              mode="out-in"
            >
              <div v-if="showInviteTokenInput">
                <p class="mb-4"> {{ $t('inviteToken.title') }} </p>

                <div class="mt-2 ion-margin-bottom">
                  <AppInput
                    :placeholder="$t('inviteToken.token')"
                    :icon="lockClosedOutline"
                    :value="inviteToken"
                    @input:update="inviteToken = $event"
                    @input:enter="verifyInviteToken"
                  />
                </div>

                <ion-button
                  expand="block"
                  mode="ios"
                  shape="round"
                  class="fw-500 fz-18"
                  @click="verifyInviteToken"
                >
                  {{ $t('commonKeys.send') }}
                </ion-button>
              </div>

              <div v-else-if="isInstruction">
                <h5 class="fw-300 mb-5 mt-3 ion-text-center custom-line-height">{{ $t('login.instruction1') }}</h5>
                <h5 class="fw-300 mb-5 ion-text-center custom-line-height">{{ $t('login.instruction2') }}</h5>
                <h5 class="fw-300 mb-5 pb-5 ion-text-center custom-line-height">{{ $t('login.instruction3') }}</h5>
                <div class="mt-5 is-flex buttons-wrap">
                  <ion-button
                    router-link="/sign-up"
                    fill="outline"
                    expand="block"
                    mode="ios"
                    shape="round"
                    class="fw-500 fz-18 uppercase"
                    @click="isInstruction = false"
                  >
                    {{ $t('commonKeys.register') }}
                  </ion-button>
                  <ion-button
                    @click="isInstruction = false"
                    expand="block"
                    :disabled="!!countDownValue"
                    mode="ios"
                    shape="round"
                    class="fw-500 fz-18 uppercase"
                  >
                    {{ countDownValue || $t('commonKeys.login') }}
                  </ion-button>
                </div>
              </div>

              <form v-else-if="showLoginInput && !isInstruction">
                <div>
                  <div class="input pt-5">
                    <AppInput
                      type="email"
                      autocomplete="email"
                      inputmode="email"
                      :value="email"
                      :placeholder="$t('login.emailLabel')"
                      :icon="mailOutline"
                      @input:update="email = $event"
                    />

                    <div class="ion-text-end mt-1">
                      <router-link
                        to="/forgot-email"
                        class="fz-12 fw-300 forgot-btn"
                      > {{ $t('login.forgotEmailLink') }} </router-link>
                    </div>
                  </div>

                  <div class="input mt-2">
                    <AppInput
                      type="password"
                      :placeholder="$t('login.passwordLabel')"
                      :icon="lockClosedOutline"
                      :value="password"
                      @input:update="password = $event"
                      @input:enter="submitLogin"
                    />

                    <div class="ion-text-end mt-1">
                      <router-link
                        to="/forgot-password"
                        class="fz-12 fw-300 forgot-btn"
                      >
                        {{ $t('login.forgotPasswordLink') }}
                      </router-link>
                    </div>
                  </div>
                </div>

                <div class="mt-5 is-flex buttons-wrap">
                  <ion-button
                    router-link="/sign-up"
                    fill="outline"
                    expand="block"
                    mode="ios"
                    shape="round"
                    class="fw-500 fz-18 uppercase"
                  >
                    {{ $t('commonKeys.register') }}
                  </ion-button>
                  <ion-button
                    @click="submitLogin({})"
                    expand="block"
                    :disabled="!!countDownValue"
                    mode="ios"
                    shape="round"
                    class="fw-500 fz-18 uppercase"
                  >
                    {{ countDownValue || $t('commonKeys.login') }}
                  </ion-button>

                <!--                <ion-button-->
                <!--                  @click="submitLogin"-->
                <!--                  expand="block"-->
                <!--                  class="gradient-btn-haweb fw-500 fz-18"-->
                <!--                >-->
                <!--                  Inloggen met Haweb-->
                <!--                </ion-button>-->
                </div>

              <!-- <div class="is-flex mt-5 pt-5">
                  <ion-button
                    mode="ios"
                    class="uppercase w-50 mr-2 --border-radius"
                    fill="outline"
                  >
                    REGISTREREN
                  </ion-button>

                  <ion-button
                    mode="ios"
                    class="uppercase w-50 --border-radius"
                    @click="submitLogin"
                  >
                    Login
                  </ion-button>
                </div> -->

              <!-- <div class="is-flex ion-justify-content-center">
                  <ion-button mode="ios" fill="clear" class="uppercase fw-500 mt-5">
                    INLOGGEN MET HAWEB
                  </ion-button>
                </div> -->
              </form>

              <TwoFAVerifyCodeInput
                v-else-if="open2FACodeInput"
                :disabled-till="countDownValue"
                @submit="loginWith2FA"
              />

              <QRCodeInput
                v-else-if="open2FAQRInput"
                :password="password"
                :loading="fetching2FAQR"
                :key="2"
                @refresh="getQrAndSecretWithOptions"
                @confirmed="loginWith2FA"
              >
                <template #footer>
                  <ion-button
                    expand="block"
                    mode="ios"
                    fill="outline"
                    @click="open2FAQRInput = false"
                  >
                    <IonIcon
                      slot="start"
                      :icon="arrowBackCircleOutline"
                    />
                    {{$t('forgotPassword.backToLoginLink')}}
                  </ion-button>

                </template>
              </QRCodeInput>

            </transition>
          </div>

          <div class="is-flex ion-justify-content-center">
            <ion-button
              mode="ios"
              fill="clear"
              class="uppercase fw-500 mt-5 fz-12 contact-us"
              target="_blank"
              :href="JIRA_CONTACT_LINK"
            >
              {{ $t('contact.us') }}
            </ion-button>
          </div>
        </div>
        <div class="logo-wrap is-flex ion-justify-content-center ion-align-items-end">
          <ion-img
            src="./assets/logo.png"
            style="height: 100px"
          />
        </div>

        <IonLoading :is-open="loading" />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { watchEffect, defineComponent, computed } from 'vue';
import { IonContent, IonPage, IonButton, IonLoading, onIonViewDidLeave, onIonViewDidEnter, IonImg, modalController, IonIcon } from '@ionic/vue';
import { mailOutline, lockClosedOutline, arrowBackCircleOutline } from 'ionicons/icons';
import { ref } from '@vue/reactivity';
import auth from '@/plugins/auth';

import TwoFAVerifyCodeInput from "@/components/login/TwoFAVerifyCodeInput";
import UserLoggedCard from "@/components/common/UserLoggedCard";
import VerifyModal from "@/components/login/VerifyModal";
import AppImg from '@/components/common/AppImg.vue';
import QRCodeInput from "@/components/profile/two-fa/QRCodeInput";


import AppInput from '@/components/common/AppInput.vue';
import { useStore } from 'vuex';

import useLoginErrorHandler from "@/composables/useLoginErrorHandler";
import usePushNotifications from "@/composables/usePushNotifications";
import useAppStorage from "@/composables/useAppStorage";

import useRequestToast from "@/composables/useRequestToast";
import useInAppBrowser from '@/composables/useInAppBrowser';
import { useRouter } from 'vue-router';

import { useI18n } from 'vue-i18n/index';

import {DEEP_LINK_REDIRECT_STORAGE_KEY, NOT_FOUNT_REDIRECT_STORAGE_KEY, JIRA_CONTACT_LINK} from "@/config/constants";


export default defineComponent({
  name: 'Login',
  components: {
    IonContent,
    IonPage,
    IonButton,
    IonLoading,
    IonImg,
    IonIcon,
    AppInput,
    UserLoggedCard,
    AppImg,
    TwoFAVerifyCodeInput,
    QRCodeInput,
  },

  setup() {
    // composables
    const router = useRouter();
    const { openUrl } = useInAppBrowser();
    const { openErrorToast, openToast } = useRequestToast();
    const { initNotifications } = usePushNotifications();
    const store = useStore();
    const { t } = useI18n();
    const { getKey, removeKey } = useAppStorage();
    const { loginErrorHandler, getQrAndSecret, open2FACodeInput, countDownValue, open2FAQRInput, fetching2FAQR } = useLoginErrorHandler();

    // variables
    const password = ref(null);
    const loading = ref(false);
    const showLoginInput = ref(true);
    const canShowAlreadyLoggedCard = ref(true);
    const inviteToken = ref(null);
    const isInstruction = ref(true);

    // computed
    const email = computed({
      get() { return store.getters['authentication/getEmailState']; },
      set(value) { store.commit('authentication/setEmailState', value); },
    });

    const isLogged = computed(() => store.getters['authentication/isLogged']);


    const openInBrowser = () => {
      openUrl('https://medischescholing.nl');
    };

    const resetLoginInputs = () => {
      open2FACodeInput.value = false;
      open2FAQRInput.value = false;
      showLoginInput.value = true;
    };

    const openModal = async (verify, complete) => {
      const modal = await modalController.create({
        component: VerifyModal,
        componentProps: {
          verifyCompleted: verify,
          completeCompleted: complete,
        },
        backdropDismiss: false,
        keyboardClose: false,
        cssClass: 'verify-modal',
      });
      await modal.present();
    };

    const submitLogin = async (extraParams = {}) => {
      auth.drivers.router.isRedirect = false;

      let redirect;
      try {
        redirect = await getKey(DEEP_LINK_REDIRECT_STORAGE_KEY);
        if (!redirect) {
          redirect = await getKey(NOT_FOUNT_REDIRECT_STORAGE_KEY);
        }
        // eslint-disable-next-line no-empty
      } catch (e) {}

      loading.value = true;
      canShowAlreadyLoggedCard.value = false;
      store
        .dispatch('authentication/login', {
          email: email.value,
          password: password.value,
          ...extraParams,
        })
        .then(() => {
          resetLoginInputs();

          store.dispatch('authentication/resetOnLoginLogout');
          initNotifications(true);

          if (redirect) {
            router.push('/main/home').then(() => router.push(redirect));
            removeKey(NOT_FOUNT_REDIRECT_STORAGE_KEY);
            removeKey(DEEP_LINK_REDIRECT_STORAGE_KEY);
            loading.value = false;
            return;
          }
          store.dispatch('profile/setProfileDetails')
            .then((data) => {
              const verifyPageCompleted = Boolean(data.professions?.length > 0 && data.profileDetails?.postal_code);
              const completeProfilePageCompleted = Boolean(store.getters['profile/getProfileDetails']?.first_name && store.getters['profile/getProfileDetails']?.last_name);

              if (completeProfilePageCompleted && verifyPageCompleted) {
                router.push('/');
              } else {
                openModal(verifyPageCompleted, completeProfilePageCompleted);
              }
            })
            .finally(() => {
              loading.value = false;
            });
        })
        .catch((error) => {
          loading.value = false;
          loginErrorHandler(error, email.value, password.value);
        })
        .finally(() => {
          auth.drivers.router.isRedirect = true;
        });
    };

    const loginWith2FA = (code) => {
      submitLogin({
        verify_code: code,
      });
    };

    const getQrAndSecretWithOptions = () => {
      getQrAndSecret(email.value, password.value);
    };

    const showInviteTokenInput = computed(() => store.state.app.inviteKeyEnabled);

    const verifyInviteToken = () => {
      loading.value = true;
      store
        .dispatch('app/validateInviteToken', inviteToken.value)
        .then((isValid) => {
          loading.value = false;
          if (isValid) {
            store.dispatch('app/saveInviteTokenStateInStorage');
          } else {
            openToast({
              message: t('inviteToken.error'),
              position: 'top',
              color: 'danger',
              duration: 2000,
            });
          }
        })
        .catch((error) => {
          loading.value = false;
          openErrorToast(error);
        });
    };

    watchEffect(() => {
      const regularInputActive = !open2FACodeInput.value && !open2FAQRInput.value;
      showLoginInput.value = regularInputActive;
    });


    onIonViewDidLeave(() => {
      canShowAlreadyLoggedCard.value = true;
    });

    onIonViewDidEnter(() => {
      const needReload = store.state.authentication.refreshAfterLogout;
      if (needReload) {
        document.location.reload();
      }
    });

    return {
      mailOutline,
      lockClosedOutline,
      arrowBackCircleOutline,

      email,
      loading,
      password,

      inviteToken,

      countDownValue,

      showLoginInput,
      open2FACodeInput,
      showInviteTokenInput,
      open2FAQRInput,
      verifyInviteToken,
      getQrAndSecretWithOptions,
      fetching2FAQR,

      isLogged,
      canShowAlreadyLoggedCard,
      submitLogin,
      loginWith2FA,
      openInBrowser,
      isInstruction,
      JIRA_CONTACT_LINK,
    };
  },
}) ;
</script>

<style lang="scss" scoped>
.title {
  font-size: 50px;
}
.subtitle {
  font-size: 20px;
  letter-spacing: 2.7px;
  margin-bottom: 30px;
}

.forgot-btn {
  color: var(--white);
  text-decoration: none;
}

.custom-line-height{
  line-height: 24px;
}

.img {
  height: 105px;
}

.gradient-btn-haweb {
  --border-radius: 20px;
  --background: linear-gradient(
    180deg,
    rgba(122, 180, 29, 1) 0%,
    rgba(108, 161, 25, 1) 100%
  );
}

.buttons-wrap {
  ion-button {
    margin: 0 7px;
  }
  /*column-gap: 20px;*/
}

.buttons-wrap ion-button {
  width: 50%;
}

.logo-wrap {
  margin: 10px 0 20px;
}

@media screen and (max-width: 320px) {
  .title {
    font-size: 60px;
  }
}
</style>
